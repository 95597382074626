<template>
  <v-card class="ma-3">
    <v-toolbar>
      <v-toolbar-title>Capstone Carrel Signup: Admin</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu offset-y>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" text>
            {{ selectedTermText }}
            <v-icon right>fal fa-chevron-down</v-icon>
          </v-btn>
        </template>
        <v-list dense style="padding: 0">
          <v-list-item v-for="{ text, value } in terms" :key="value" @click="selectedTerm = value">
            <v-list-item-title>{{ text }}</v-list-item-title>
          </v-list-item>
          <v-divider></v-divider>
          <new-term-dialog @added="addTerm"></new-term-dialog>
        </v-list>
      </v-menu>
    </v-toolbar>
    <v-tabs v-if="selectedTerm" v-model="tab">
      <v-tab>Overview</v-tab>
      <v-tab>List</v-tab>
      <v-tab>Term Settings</v-tab>
      <v-tab>Capstone Students</v-tab>
    </v-tabs>
    <v-tabs-items v-if="selectedTerm" v-model="tab">
      <v-tab-item>
        <v-row>
          <v-col>
            <carrel-layout :selected-carrel="selectedCarrel" :carrel-status="carrelStatus" @click="selectCarrel"></carrel-layout>
            <v-toolbar>
              <span style="margin-right:15px">Color Key:</span>
              <div>
                <div style="background-color:#4CAF50;display:inline-block;width:10px;height:10px;margin-right:5px"></div> Selected
              </div>
              <v-spacer></v-spacer>
              <div>
                <div style="background-color:#FFA000;display:inline-block;width:10px;height:10px;margin-right:5px"></div> Partially Filled
              </div>
              <v-spacer></v-spacer>
              <div>
                <div style="background-color:#FF5252;display:inline-block;width:10px;height:10px;margin-right:5px"></div> Filled
              </div>
            </v-toolbar>
          </v-col>
          <v-col>
            <div v-if="selectedCarrel">
              <h3 style="margin-bottom:20px">Carrel {{ selectedCarrel }}</h3>
              <div v-for="({ name, person }, index) in selectedSlots" :key="name">
                <div v-if="person">
                  <directory-card v-if="person" :person="person"></directory-card>
                  <v-btn color="error" style="margin:20px 0" @click="removeFromCarrel(name, person, index)">Remove {{ person.name.first + ' ' + person.name.last || name }}</v-btn>
                </div>
                <span v-else-if="name">{{ name }}</span>
                <v-alert v-else type="info">Nobody has signed up for this slot</v-alert>
                <v-divider v-if="selectedSlots.length > index + 1"></v-divider>
              </div>
            </div>
            <v-card v-else>
              <v-card-text>Select a carrel in the diagram to the left to see the details of that carrel.</v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-tab-item>
      <v-tab-item>
        <v-toolbar>
          <v-text-field v-model="search" append-icon="far fa-search" label="Search" single-line solo outlined hide-details></v-text-field>
        </v-toolbar>
        <v-data-table :items="carrels" :headers="headers" :search="search"></v-data-table>
      </v-tab-item>
      <v-tab-item>
        <v-card-text>
          <v-row>
            <v-col :cols="12" md="4">
              <date-picker v-model="openDate" label="Open Date"></date-picker>
            </v-col>
            <v-col :cols="12" md="4">
              <time-picker v-model="openTime" label="Open Time"></time-picker>
            </v-col>
            <v-col :cols="12" md="4">
              <date-picker v-model="cutoffDate" label="Cutoff Date"></date-picker>
            </v-col>
            <v-col :cols="12" md="8">
              <v-text-field v-model="email" label="Email to receive reservation notifications" hint="Will receive an email whenever someone reserves a slot; leave blank for no notifications" outlined persistent-hint></v-text-field>
            </v-col>
            <v-col :cols="1" class="d-none d-md-flex"></v-col>
            <v-col :cols="11" md="3">
              <v-dialog v-if="!secondSlotEnabled" v-model="secondSlotDialog" width="400" persistent>
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" outlined class="mt-2">Enable Second Slot</v-btn>
                </template>
                <v-card>
                  <v-card-title>Enable Second Slot</v-card-title>
                  <v-card-text>
                    <v-progress-linear v-if="slotProgressTotal > 0" :value="slotProgressCount / slotProgressTotal * 100"></v-progress-linear>
                    <p v-else>Click the button below to enable the second slot on each of the carrels.</p>
                  </v-card-text>
                  <v-card-actions v-if="!addingSecondSlot">
                    <v-btn v-if="slotProgressTotal > 0" text @click="secondSlotDialog = false">Done</v-btn>
                    <template v-else>
                      <v-btn text color="success" @click="enableSecondSlot">Enable Second Slot</v-btn>
                      <v-spacer></v-spacer>
                      <v-btn text @click="secondSlotDialog = false">Cancel</v-btn>
                    </template>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <div v-else>2 slots enabled for this term</div>
            </v-col>
          </v-row>
          <v-subheader>Contract Text</v-subheader>
          <html-editor v-model="contractText"></html-editor>
        </v-card-text>
        <v-card-actions>
          <v-btn color="success" @click="saveUpdates">
            <v-icon left>fal fa-save</v-icon>
            Save
          </v-btn>
        </v-card-actions>
      </v-tab-item>
      <v-tab-item>
        <v-card>
          <v-card-text>
            <p>These are all of the students who are registered for a Capstone course this academic year, along with their major(s), and whether or not they have reserved a Capstone carrel within the given academic year.</p>
            <v-select v-model="studentFilter" :items="studentFilterOptions" label="Filter by status" outlined multiple chips deletable-chips dense>
              <template v-slot:append-outer>
                <email-dialog :data="emailData" :merge-fields="emailMergeFields">
                  <template v-slot:activator="{ on }">
                    <v-btn v-on="on" style="margin-top:-7px">
                      <v-icon left>fal fa-envelope</v-icon>
                      Email Students
                    </v-btn>
                  </template>
                </email-dialog>
              </template>
            </v-select>
          </v-card-text>
        </v-card>
        <v-data-table :items="filteredCapstoneStudents" :headers="capstoneHeaders">
          <template v-slot:item.major="{ item }">
            <v-chip v-for="major in item.major" :key="major">{{ major }}</v-chip>
          </template>
          <template v-slot:item.courses="{ item }">
            <div v-for="{ course, term } in item.courses" :key="term + course">{{ course }} ({{ term.substring(4, 6) === '60' ? 'Fall' : 'Spring' }})</div>
          </template>
          <template v-if="selectedTermIsSpring" v-slot:item.carrel="{ item }">
            <v-badge v-for="{ carrel, term } in item.carrel" :key="term" :content="term.substring(4, 6) === '60' ? 'Fall' : 'Spring'" :color="term.substring(4, 6) === '60' ? 'orange' : 'primary'" offset-x="10" offset-y="10">
              <v-chip label outlined>Carrel {{ carrel }}</v-chip>
            </v-badge>
          </template>
          <template v-else v-slot:item.carrel="{ item }">
            <v-chip v-for="{ carrel, term } in item.carrel" :key="term" label outlined>Carrel {{ carrel }}</v-chip>
          </template>
        </v-data-table>
      </v-tab-item>
    </v-tabs-items>
    <v-card-text v-else>
      <p>Please select a term in the upper-right corner</p>
    </v-card-text>
  </v-card>
</template>
<script>
import { computed, onMounted, ref, watch } from '@vue/composition-api'
import { yearMonDayFormat } from '@/helpers/formatters'
import { courseList } from '@/components/student/capstone'

export default {
  components: {
    NewTermDialog: () => import('@/components/library/carrel/newTerm'),
    CarrelLayout: () => import('@/components/library/carrel/carrelLayout'),
    DirectoryCard: () => import('@/components/greatscots/CardSingle'),
    DatePicker: () => import('@/components/forms/inputs/DatePicker'),
    TimePicker: () => import('@/components/forms/inputs/TimePicker'),
    HtmlEditor: () => import('@/components/forms/HTMLEditor'),
    EmailDialog: () => import('@/components/system/emailDialog')
  },
  setup (props, { root }) {
    const termService = root.$feathers.service('library/carrel-term')
    const service = root.$feathers.service('library/carrel')

    const selectedTerm = computed({
      get: () => root.$store.state.library.carrel.term,
      set: (term) => root.$store.commit('library/setCarrelTerm', term)
    })
    const selectedTermText = computed(() => {
      if (selectedTerm.value != null && selectedTerm.value !== '') {
        for (const { text, value } of terms.value) {
          if (value === selectedTerm.value) return text
        }
      }
      return 'Select Term'
    })
    const selectedTermIsSpring = computed(() => selectedTermText.value.split(' ')[0] === 'Spring')
    const terms = ref([])
    watch(selectedTerm, () => {
      if (selectedTerm.value != null && selectedTerm.value !== '') {
        loadCarrels()
        loadCapstoneStudents()
      }
    })

    function addTerm ({ term, text }) {
      selectedTerm.value = term
      terms.value.push({ text, value: term })
    }

    const tab = ref(0)
    const selectedCarrel = ref(null)
    const selectedSlots = ref([])
    async function selectCarrel (carrel) {
      selectedCarrel.value = carrel
      const { data } = await service.find({ query: { term: selectedTerm.value, carrel } })
      if (data.length > 0) {
        selectedSlots.value = []
        for (const { name, directoryId } of data[0].slots) {
          let person = null
          if (directoryId) {
            person = await root.$feathers.service('directory/people').get(directoryId)
          }
          selectedSlots.value.push({ name, person })
        }
      } else {
        console.log('No data found')
      }
    }

    const carrels = ref([])
    const carrelStatus = ref({})
    const headers = ref([
      { text: 'Carrel', value: 'carrel' },
      { text: 'Slot 1', value: 'slot1' },
      { text: 'Slot 2', value: 'slot2' }
    ])
    async function loadCarrels () {
      const { data: termData } = await termService.find({ query: { term: selectedTerm.value } })
      termId.value = termData[0]._id
      secondSlotEnabled.value = termData[0].slots === 2
      openDate.value = yearMonDayFormat(termData[0].open)
      let str = new Date(termData[0].open).toLocaleTimeString()
      let [hour, min, sec] = str.split(':')
      const ampm = sec.substring(3)
      hour = parseInt(hour)
      if (ampm === 'PM' && hour !== 12) openTime.value = (hour + 12) + ':' + min
      else openTime.value = hour + ':' + min
      cutoffDate.value = yearMonDayFormat(termData[0].cutoff)
      email.value = termData[0].email || ''
      contractText.value = termData[0].text

      const query = { term: selectedTerm.value, $limit: 0 }
      const { total } = await service.find({ query })
      query.$limit = 50
      const arr = []
      const obj = {}
      for (let i = 0; i < total; i += 50) {
        query.$skip = i
        const { data } = await service.find({ query })
        for (const { carrel, slots } of data) {
          let spotsTaken = 0
          let spotsAvailable = 0
          slots.forEach(({ available }) => {
            if (available) spotsAvailable++
            else spotsTaken++
          })
          let status = 'full'
          if (spotsAvailable === 0) status = 'full'
          else if (spotsTaken === 0) status = 'empty'
          else status = 'partial'
          obj[carrel] = status
          arr.push({
            carrel,
            slot1: slots.length > 0 && 'name' in slots[0] ? slots[0].name : '--Available--',
            slot2: slots.length > 1 && 'name' in slots[1] ? slots[1].name : (slots.length > 1 ? '--Available--' : '--Offline--'),
            slots
          })
        }
      }
      carrels.value = arr
      carrelStatus.value = obj
    }

    onMounted(() => {
      // Look up distinct terms in the library/carrel service to populate the list of terms
      root.$feathers.service('library/carrel-term').find().then(({ data }) => {
        const termLookup = data.map(({ term }) => term)
        root.$feathers.service('system/term').find({ query: { term: { $in: termLookup }, $sort: { start: -1 } } }).then(({ data }) => {
          if (data.length > 0) {
            terms.value = data.map(({ term: value, label: text }) => { return { text, value } })
            selectedTerm.value = terms.value[0].value
          }
        })
      })
    })

    const search = ref('')

    async function removeFromCarrel (name, person, index) {
      if (confirm('Are you sure you want to remove ' + person.name.first + ' ' + person.name.last + ' from carrel ' + selectedCarrel.value + '?')) {
        const { data } = await service.find({ query: { term: selectedTerm.value, carrel: selectedCarrel.value } })
        if (data.length > 0) {
          const { _id, slots } = data[0]
          let status = 'empty'
          let foundIndex = -1
          for (let i = 0; i < slots.length; i++) {
            if (slots[i].name === name) {
              await service.patch(_id, { ['slots.' + i]: { available: true } })
              foundIndex = i
            } else if (!slots[i].available) {
              status = 'partial'
            }
          }
          if (foundIndex >= 0) {
            slots.splice(foundIndex, 1, { available: true })
          }
          selectedSlots.value = slots
          carrelStatus.value[selectedCarrel.value] = status
        }
      }
    }

    const secondSlotEnabled = ref(false)
    const secondSlotDialog = ref(false)
    const addingSecondSlot = ref(false)
    const slotProgressCount = ref(0)
    const slotProgressTotal = ref(0)
    async function enableSecondSlot () {
      addingSecondSlot.value = true
      const query = { term: selectedTerm.value, $limit: 0, $sort: { carrel: 1 } }
      const { total } = await service.find({ query })
      slotProgressTotal.value = total
      query.$limit = 10
      for (let i = 0; i < total; i += 10) {
        const { data } = await service.find({ query })
        for (const { _id, slots } of data) {
          try {
            if (slots.length === 1) {
              await service.patch(_id, { $push: { slots: { available: true } } })
            } else if (slots.length === 0) {
              await service.patch(_id, { slots: [{ available: true }, { available: true }] })
            }
            slotProgressCount.value++
          } catch (e) {
            console.log(e)
          }
        }
      }
      addingSecondSlot.value = false
    }

    const termId = ref(null)
    const openDate = ref('')
    const openTime = ref('')
    const cutoffDate = ref('')
    const email = ref('')
    const contractText = ref('')
    async function saveUpdates () {
      const cutoff = new Date(cutoffDate.value + ' 08:00:00')
      const open = new Date(openDate.value + ' ' + openTime.value + ':00')
      await termService.patch(termId.value, { text: contractText.value, cutoff, open, email: email.value })
      root.$store.dispatch('main/snackbar', { color: 'success', text: 'Term settings updated', timeout: 6000 })
    }

    const capstoneHeaders = ref([
      { text: 'Banner ID', value: 'bannerId' },
      { text: 'Last', value: 'last' },
      { text: 'First', value: 'first' },
      { text: 'Major(s)', value: 'major' },
      { text: 'Capstone Course(s)', value: 'courses' },
      { text: 'Reserved Carrel', value: 'carrel' }
    ])
    const studentFilter = ref([])
    const studentFilterOptions = computed(() => {
      const arr = [
        { text: 'Has a Carrel This Spring', value: 'has' },
        { text: 'Had a Carrel Last Fall', value: 'had' },
        { text: 'No Carrel', value: 'not' },
        { text: 'Eligible for Second', value: 'elig' }
      ]
      if (!selectedTermIsSpring.value) {
        arr.splice(0, 1, { text: 'Has a Carrel', value: 'has' })
        arr.splice(1, 1)
      }
      return arr
    })
    const capstoneStudents = ref([])
    const filteredCapstoneStudents = computed(() => capstoneStudents.value.filter(({ carrel, major }) => studentFilter.value.length === 0 || (studentFilter.value.includes('has') && carrel.filter(({ term }) => term === selectedTerm.value).length > 0) || (studentFilter.value.includes('had') && carrel.filter(({ term }) => term < selectedTerm.value).length > 0) || (studentFilter.value.includes('not') && carrel.length === 0) || (studentFilter.value.includes('elig') && carrel.length >= 1 && major.length > 1)))
    async function loadCapstoneStudents () {
      let term = selectedTerm.value
      if (selectedTermIsSpring.value) {
        let fall = parseInt(selectedTerm.value) - 45
        term = { $in: [selectedTerm.value, fall + ''] }
      }
      const aggregate = [
        { $unwind: '$schedule' },
        { $project: {
          term: 1,
          pidm: 1,
          bannerId: 1,
          course: '$schedule.title',
          major: '$academics.major',
          courseShort: { $substr: ['$schedule.title', 0, 6] }
        } },
        { $match: {
          term,
          courseShort: { $in: courseList }
        } },
        { $lookup: {
          from: 'Directory',
          localField: 'pidm',
          foreignField: 'pidm',
          as: 'directory'
        } },
        { $project: {
          term: 1,
          pidm: 1,
          bannerId: 1,
          course: 1,
          major: 1,
          directory: { $first: '$directory' }
        } },
        { $group: {
          _id: {
            pidm: '$pidm',
            bannerId: '$bannerId',
            email: '$directory.email',
            first: '$directory.name.first',
            last: '$directory.name.last',
            directoryId: '$directory._id',
            major: '$major'
          },
          courses: { $push: { course: '$course', term: '$term' } }
        } },
        { $lookup: {
          from: 'Library-Carrel',
          localField: '_id.directoryId',
          foreignField: 'slots.directoryId',
          as: 'carrel',
          pipeline: [
            { $match: { term } },
            { $project: { carrel: 1, term: 1 } }
          ]
        } },
        { $project: {
          pidm: '$_id.pidm',
          bannerId: '$_id.bannerId',
          first: '$_id.first',
          last: '$_id.last',
          email: '$_id.email',
          courses: 1,
          major: '$_id.major',
          carrel: 1
        } },
        { $sort: {
          last: 1,
          first: 1
        } }
      ]
      const { data } = await root.$feathers.service('student/term-detail').find({ query: { aggregate } })
      capstoneStudents.value = data
    }

    const emailMergeFields = ref([
      { label: 'First', value: 'first' },
      { label: 'Last', value: 'last' },
      { label: 'Major(s)', value: 'major' },
      { label: 'Capstone Course(s)', value: 'courses' },
      { label: 'Reserved Carrel', value: 'carrel' }
    ])
    const emailData = computed(() => filteredCapstoneStudents.value.map((row) => { return { ...row, major: row.major && Array.isArray(row.major) ? row.major.join(', ') : '', courses: row.courses && Array.isArray(row.courses) ? row.courses.join('; ') : '' } }))

    return {
      selectedTerm,
      selectedTermText,
      selectedTermIsSpring,
      terms,
      addTerm,
      tab,
      selectedCarrel,
      selectedSlots,
      selectCarrel,
      carrels,
      carrelStatus,
      headers,
      loadCarrels,
      search,
      removeFromCarrel,
      secondSlotEnabled,
      secondSlotDialog,
      addingSecondSlot,
      slotProgressCount,
      slotProgressTotal,
      enableSecondSlot,
      termId,
      openDate,
      openTime,
      cutoffDate,
      email,
      contractText,
      saveUpdates,
      studentFilter,
      studentFilterOptions,
      capstoneStudents,
      filteredCapstoneStudents,
      capstoneHeaders,
      loadCapstoneStudents,
      emailMergeFields,
      emailData
    }
  }
}
</script>
